import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Хоёрдогч зах зээлийн арилжааны захиалга үүсгэх",
  "description": null,
  "author": "OTC help",
  "category": "market",
  "date": "2022-04-08T00:00:00.000Z",
  "featured": true,
  "tags": [],
  "order": 4
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Хоёрдогч зах зээлийн арилжааны захиалга үүсгэх`}</h2>
    <ol>
      <li parentName="ol">{`Үндсэн цэснээс `}<strong parentName="li">{`[Market]`}{`-`}{`[Secondary]`}</strong>{` хэсгийг сонгоно`}</li>
      <li parentName="ol">{`Дэлгэцийн баруун дээд булан дахь `}<strong parentName="li">{`[Create RFQ]`}</strong>{` товчийг дарна`}</li>
      <li parentName="ol">{`Захиалга өгөх хэсэгт дараах мэдээллүүдийг оруулна.`}
        <ul parentName="li">
          <li parentName="ul"><strong parentName="li">{`[Security]`}</strong>{` - хэсэгт захиалга өгөх үнэт цаасыг жагсаалтаас сонгоно`}</li>
          <li parentName="ul"><strong parentName="li">{`[Visibility]`}</strong>{` - хэсэгт нийтэд нээлттэй `}<strong parentName="li">{`[Public]`}</strong>{` эсвэл тухайлсан оролцогч болон оролцогчдод тусгайлан `}<strong parentName="li">{`[Private]`}</strong>{` захиалга үүсгэх гэсэн сонголтыг хийнэ`}</li>
          <li parentName="ul"><strong parentName="li">{`[Direct offer to]`}{`-`}{`[Visibility]`}</strong>{` хэсэгт `}<strong parentName="li">{`[Private]`}</strong>{` сонголт хийсэн тохиолдолд тусгайлан захиалга илгээх бүртгэлтэй шууд оролцогчийг жагсаалтаас сонгоно, нэгээс олон оролцогч сонгох боломжтой`}</li>
          <li parentName="ul"><strong parentName="li">{`[Side]`}</strong>{` - хэсэгт авах `}<strong parentName="li">{`[Buy]`}</strong>{` эсвэл `}<strong parentName="li">{`[Sell]`}</strong>{` зарах гэсэн сонголтыг хийнэ`}</li>
          <li parentName="ul"><strong parentName="li">{`[Minimum quantity]`}</strong>{` - хэсэгт үнэт цаасны захиалгын биелэгдэх доод хэмжээ буюу хамгийн бага тоо ширхгийг оруулна`}</li>
          <li parentName="ul"><strong parentName="li">{`[Maximum quantity]`}</strong>{` - хэсэгт үнэт цаасны захиалгын биелэгдэх дээд хэмжээ буюу хамгийн их тоо ширхгийг оруулна`}</li>
          <li parentName="ul"><strong parentName="li">{`[Price]`}</strong>{` - хэсэгт захиалга өгөх нэгжийн үнийн дүнг оруулна`}</li>
          <li parentName="ul"><strong parentName="li">{`[End date]`}</strong>{` - хэсэгт захиалгын хүчинтэй хугацаа дуусах огноог сонгон оруулна. Захиалгын хүчинтэй хугацаанд захиалга биелээгүй тохиолдолд тухайн захиалга самбар дээрээс устах болно.`}</li>
        </ul>
      </li>
    </ol>
    <p>{`Дээрх захиалгын мэдээллүүдийг оруулан `}<strong parentName="p">{`[Create]`}</strong>{` товчийг дарж захиалгыг амжилттай үүсгэнэ.
Оруулсан захиалгын мэдээллийг бүхэлд нь арилган шинээр оруулах бол `}<strong parentName="p">{`[Reset]`}</strong>{` товчийг дарж арилгана.`}</p>
    <p><strong parentName="p">{`[Public]`}</strong>{` тохиргоотой илгээсэн захиалгыг платформын нийт оролцогчид `}<strong parentName="p">{`[Secondary]`}</strong>{` дэд цэсний `}<strong parentName="p">{`[Buy offers]`}{`, `}{`[Sell offers]`}</strong>{` хэсгээс харах боломжтой`}</p>
    <p><strong parentName="p">{`[Private]`}</strong>{` тохиргоотой илгээсэн захиалгыг тусгайлан сонгосон оролцогчид л харах боломжтой бөгөөд захиалгын урд хэсэгт түгжээ бүхий тэмдэг байна.`}</p>
    <ol {...{
      "start": 4
    }}>
      <li parentName="ol">{`Үүсгэсэн захиалгыг засварлах бол тухайн захиалгын хойд хэсэг дэх `}<strong parentName="li">{`[Actions]`}</strong>{` товчийг дарж `}<strong parentName="li">{`[Update]`}</strong>{` хэсгийг сонгон мэдээллийг өөрчилнө. Захиалгыг хүчинтэй хугацаа дуусахаас өмнө цуцлах бол `}<strong parentName="li">{`[Close]`}</strong>{` товчийг дарна.`}</li>
    </ol>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      